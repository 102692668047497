// overriding bootstrap variables in _boostrap-override.scss

$family-base: "proxima-nova",sans-serif;
$family-header: "proxima-nova",serif;

// font-size
$font-size-desktop: 21px;
$font-size-mobile: 16px;

$weight-light: 300;
$weight-normal: $weight-light;
$weight-regular: 400;
$weight-medium: 500;
$weight-semi-bold: 600;
$weight-bold: 700;

// colors - sister colours from bootrap
$color-blue:    #0d6cb4;
$color-red:     #c20f2f;
$color-orange:  #e2953e;
$color-yellow:  #fad509;
$color-green:   #28a745;
$color-black:   #252525;

// more colors
$color-gold:  #faa222;
$color-grey:  #495057;
$color-dark-gray: $color-black;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;

// site colors
$primary-color: $color-orange;
$font-color: $color-grey;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color:#ebebeb;
$link-color: $primary-color;
$link-hover-color: $color-yellow;

// top-bar
//$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 1.6rem, desktop: 2rem);
$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 2.7rem, small-device:2.8rem, tablet:3.5rem, desktop: 3.1rem);
$top-bar-height: (mobile: 44px, small-device:56px, tablet:56px, desktop: 56px);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;